.App {
}

@media screen and (max-width: 768px) {
  .App {
    background: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

@media screen and (max-height: 400px) {
}
